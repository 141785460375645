<template>
    <div>
        <ol>
            <li class="text text-margin">{{ $t('faq.tivimate.step4.addPlaylist') }}</li>
            <div class="screenshot">
                <img src="../assets/screen-1.png" alt="" class="step-image">
            </div>
            <li class="text mt-5 text-margin">{{ $t('faq.tivimate.step4.enterURL') }}</li>
            <div class="screenshot">
                <img src="../assets/screen-2.png" alt="" class="step-image">
            </div>
        </ol>
        <p class="text mt-4">{{ $t('faq.tivimate.step4.addThePlaylist') }}</p>
        <p class="text text-margin">{{ $t('faq.tivimate.step4.insert') }}</p>
        <p class="text">{{ $t('faq.tivimate.step4.liveTv') }}</p>
        <a class="text link text-margin" :href="liveTVLink" target="_blank">{{ liveTVLink }}</a>
        <p class="text mt-5">{{ $t('faq.tivimate.step4.movies') }}</p>
        <a class="text link" :href="moviesLink" target="_blank">{{ moviesLink }}</a>
        <p class="text mt-5 text-margin">{{ $t('faq.tivimate.step4.tvShows') }}</p>
        <p class="text text-margin">{{ $t('faq.tivimate.step4.sortedByYear') }}</p>
        <p class="text">{{ $t('faq.tivimate.step4.youMustAdd') }}</p>
        <div class="text-margin">
            <a class="text link" :href="fullCatalogLink" target="_blank">{{ fullCatalogLink }}</a>
        </div>
        <p class="text text-margin">{{ $t('faq.tivimate.step4.afterYouHaveEntered') }}</p>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
    computed: {
        ...mapGetters(['brandConfig']),
        liveTVLink() {
            return `${this.brandConfig.tvLink}/api/list/USER_NAME/PASSWORD/m3u8/livetv`
        },
        moviesLink() {
            return `${this.brandConfig.tvLink}/api/list/USER_NAME/PASSWORD/m3u8/movies`
        },
        fullCatalogLink() {
            return `${this.brandConfig.tvLink}/api/list/USER_NAME/PASSWORD/m3u8/tvshows/CHANNEL_NUMBER (1-14)`
        }
    }
}
</script>
<style scoped>
@import "../../../components/styles/guides-default-styles.css";
</style>
